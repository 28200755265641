import React, { useState } from 'react';
import { Modal, Pagination, PaginationProps, Spin, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { DataType } from '../../interface/list-user/list_user.interface';

const PopupDevices = (props: any) => {
	const { currentUser, isModalOpenDevices, handleOk, handleCancel } = props;
	const columns: ColumnsType<DataType> = [
		{
			title: 'NO.',
			dataIndex: 'index',
			key: 'index',
			width: '5%',
			className: 'text-center',
			render: (text, object, index) => index + 1,
		},
		{
			title: 'DEVICE',
			dataIndex: 'deviceId',
			key: 'deviceId',
			className: 'text-center',
		},
		{
			title: 'DEVICE MODEL',
			dataIndex: 'device_model',
			key: 'device_model',
		},
		{
			title: 'OS VERSION',
			dataIndex: 'os_version',
			key: 'os_version',
		},
		{
			title: 'APP VERSION',
			dataIndex: 'app_version',
			key: 'app_version',
		},
		{
			title: 'DELETED',
			key: 'isDeleted',
			dataIndex: 'isDeleted',
			render: (text, object) => {
				return object.isDeleted ? 'Yes' : 'No';
			},
		},
		{
			title: 'CREATED',
			key: 'created',
			dataIndex: 'created',
			render: (text, object) => {
				return text ? new Date(text).toLocaleString() : '';
			},
		},
		{
			title: 'DEVICE TOKEN',
			dataIndex: 'device_token',
			key: 'device_token',
		},
	];

	return (
		<div>
			<Modal
				title={
					<div>
						User name:{' '}
						<span className="text-red-600">
							{currentUser?.first_name} {currentUser?.last_name}
						</span>
					</div>
				}
				destroyOnClose={true}
				visible={isModalOpenDevices}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={null}
				className="p-2 rounded-sm"
				width="85%"
				okButtonProps={{ style: { backgroundColor: '#13ae81', borderColor: '#13ae81' } }}
			>
				<div className="mb-4 flex justify-end font-semibold">
					Total: {currentUser?.devices?.length | 0} devices
				</div>
				<Table
					bordered
					columns={columns}
					scroll={{ x: '100%' }}
					dataSource={currentUser?.devices.sort((a:any, b:any) => (a.created > b.created ? -1 : 1))}
					// onChange={onChangePlan}
					// locale={{
					// 	emptyText: (
					// 		<>
					// 			{spinValues ? (
					// 				<Spin indicator={antIcon} spinning={spinValues} />
					// 			) : (
					// 				<span className="italic font-medium  text-center">No data</span>
					// 			)}
					// 			,
					// 		</>
					// 	),
					// }}
				/>
				{/* <div className="flex justify-end mt-3 ">
					<Pagination
						// current={Number(pageValue)}
						showSizeChanger
						defaultCurrent={1}
						// defaultPageSize={objParams.size}
						total={currentUser?.groupUsers?.length | 0}
						onChange={onShowSizeChange}
						locale={{ items_per_page: ' Users per page' }}
					/>
				</div> */}
			</Modal>
		</div>
	);
};

export default PopupDevices;
